import React from 'react';

import Layout from '@layout/Layout';
import Seo from '@components/Seo/Seo';

import PageHeader from '@components/PageHeader/PageHeader';

const MappaDelSito = ({ location }) => {
  const title = 'Mappa del sito';
  return (
    <Layout>
      <Seo title={title} url={location.pathname} />
      <div className="page_container">
        <PageHeader title={title} />
      </div>
    </Layout>
  );
};

export default MappaDelSito;

// export const query = graphql`
//   query {
//     allSitePage {
//       edges {
//         node {
//           context {
//             name
//           }
//           path
//         }
//       }
//     }
//   }
// `;
